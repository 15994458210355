.match-wrapper-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    padding-top: 15px;
}
.back-button {
    border: none;
    padding: 10px;
    font-weight: 700;
    font-size: 18pt;
}
.match-date-wrapper{
    border-radius: 25px;
    padding: 0 20px 0 20px;
    background-color: black;
}
.match-date-wrapper > h1{
    color: white;
}