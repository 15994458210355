.Home{
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
}

.matches-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 98%;
    align-items: flex-start;
    text-decoration: none
}

.match-item-wrapper {
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    background-color: #f1f1f1;
    margin-bottom: 3px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 5px;
    text-decoration: none;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.match-item-wrapper:visited {
    background-color: #cdcaca;
}

.match-item-img {
    max-width: 50px;
    height: 50px;
    margin-right: 20px;
}

.second-image {
    margin-left: 20px;
}
.match-item-wrapper:hover {
    background-color: #000;
    color: #f1f1f1;

}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10;
    align-items: center;
    width: 100%;
}

.header p {
    border-radius: 4px;
    border: 1px solid #ccc;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;

}